import { useLocation } from "@gatsbyjs/reach-router";
import Box from "@municipio/gatsby-theme-basic/src/components/Box";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { Icon, Link } from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./NoticeModule.module.css";

NoticeModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.shape({
    modNoticeOptions: PropTypes.shape({
      noticeText: PropTypes.string,
      noticeType: PropTypes.string.isRequired,
    }),
    noticeLink: PropTypes.shape({
      link: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
    link: PropTypes.string,
  }),
};

export default function NoticeModule({
  module: {
    modNoticeOptions: { noticeText, noticeType },
    noticeLink,
  },
  styles = defaultStyles,
  title,
  ...restProps
}) {
  const { url, title: linkTitle } = noticeLink?.link || {};
  const location = useLocation();
  const addSpace =
    location.pathname.includes("nyheter") ||
    location.pathname.includes("driftinformation") ||
    location.pathname.includes("anslagstavla");

  return (
    <ModuleWrapper
      as={Box}
      border={false}
      title={
        title && (
          <div className={styles.hasIcon}>
            <Icon name={noticeType} className={styles.icon} />
            <span>{title}</span>
          </div>
        )
      }
      styles={styles}
      css={{
        "--box-background": `var(--color-${noticeType})`,
        "--box-color": `var(--color-${noticeType}-foreground, #ffffff)`,
        marginBottom: addSpace ? "40px" : "0",
      }}
      {...restProps}
    >
      <div className={clsx(styles.content, !title && styles.hasIcon)}>
        {!title && <Icon name={noticeType} className={styles.icon} />}
        {noticeText}
      </div>
      {url && (
        <Link className={styles.link} to={url}>
          {linkTitle}
        </Link>
      )}
    </ModuleWrapper>
  );
}
